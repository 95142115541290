














import Vue from "vue";
import { AssessmentListingHeaderOptions } from "@/interfaces/responses/assessments/assessments";
import { mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "AssessmentsListingHead",
  data() {
    return {
      table_headers: [] as AssessmentListingHeaderOptions[]
    };
  },
  watch: {
    get_site_direction() {
      this.set_header();
    }
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  mounted() {
    this.set_header();
  },
  methods: {
    set_header() {
      this.table_headers = [
        {
          title: this.$t("assessments.listing.col-1")
        },
        {
          title: this.$t("assessments.listing.col-2")
        },
        {
          title: this.$t("assessments.listing.col-3")
        },
        {
          title: this.$t("assessments.listing.col-4")
        }
      ];
    }
  }
});
