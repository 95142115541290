



























































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ASSESSMENT_LISTING } from "@/store/modules/recruiter/constants";
import { download_file, format_date } from "@/utils/global";
import {
  GET_ASSESSMENT_PDF,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { AssessmentsListingResponse } from "@/interfaces/responses/assessments/assessments";
export default Vue.extend({
  name: "AssessmentsListingBody",
  data() {
    return {
      download_report_loading: false
    };
  },

  async mounted() {
    //
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("recruiter", {
      get_assessment_listing: ASSESSMENT_LISTING
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  methods: {
    ...mapActions("common", {
      fetch_assessment_pdf: GET_ASSESSMENT_PDF
    }),
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    format_date,
    async get_assessment_report(assessment: AssessmentsListingResponse.Result) {
      this.download_report_loading = true;
      const url = await this.fetch_assessment_pdf(assessment.id);
      if (!url) this.root_error(this.$t("errors.assessment-report-failed"));
      else if (url.includes("http") || url.includes("https"))
        await download_file(url);
      else this.root_notification(this.$t("success-messages.pdf-creation"));
      this.download_report_loading = false;
    }
  }
});
