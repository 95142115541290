


















































































import Vue from "vue";
import { get_color_by_name } from "@/utils/colors";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  ASSESSMENT_LISTING,
  ASSESSMENT_LISTING_DATES_FILTER,
  DELETE_ASSESSMENT_LISTING_DATES_FILTER
} from "@/store/modules/recruiter/constants";
import { SELECTED_USERS } from "@/store/modules/common/constants";
import AssessmentsListingHead from "@/components/recruiter/assessments/AssessmentsListingHead.vue";
import AssessmentsListingBody from "@/components/recruiter/assessments/AssessmentsListingBody.vue";
import { FetchAssessmentListingPayload } from "@/store/modules/recruiter/interfaces";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { AutocompleteAssessmentSearch } from "@/interfaces/assessments";
import AutoCompleteUserSearch from "@/components/shared/AutoCompleteUserSearch.vue";
import moment from "moment";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";

export default Vue.extend({
  name: "RecruiterAssessmentListing",
  components: {
    DataNotFound,
    AssessmentsListingBody,
    AssessmentsListingHead,
    AutoCompleteUserSearch
  },
  data(): AutocompleteAssessmentSearch & { not_found_error: string } {
    return {
      not_found_error: this.$t("errors.assessments-not-found").toString(),
      date_picker_menu: false,
      date_picker_field: [],
      date_range_value: null,
      selected_searched_user: []
    };
  },
  mounted() {
    if (this.get_selected_dates?.length) {
      this.date_picker_field = this.get_selected_dates;
    }
    if (!this.fetch_users.length && !this.date_picker_field.length)
      this.extract_assessment_listing();
  },
  watch: {
    date_picker_field(n) {
      if (n.length > 0 && n.length < 3) this.format_date_range(n);
      else this.date_range_value = null;
    }
  },
  computed: {
    ...mapGetters("recruiter", {
      get_assessment_listing: ASSESSMENT_LISTING,
      get_selected_dates: ASSESSMENT_LISTING_DATES_FILTER
    }),
    ...mapGetters("common", {
      fetch_users: SELECTED_USERS
    })
  },
  methods: {
    ...mapMutations("recruiter", {
      set_selected_dates: ASSESSMENT_LISTING_DATES_FILTER,
      delete_selected_dates: DELETE_ASSESSMENT_LISTING_DATES_FILTER
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      fetch_assessment_listing: ASSESSMENT_LISTING
    }),
    async searched_user(users: SearchUserObject[]) {
      if (users?.length > 0) {
        this.selected_searched_user = users;
      } else {
        this.reset_users();
      }
      await this.extract_assessment_listing(
        this.get_assessment_listing.pagination
      );
    },
    async clear_dates() {
      this.reset_dates();
      await this.extract_assessment_listing(
        this.get_assessment_listing.pagination
      );
    },
    transform_calender_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("stroke", get_color_by_name("primary"));
      return icon;
    },
    async extract_assessment_listing(page = 1, limit = 12) {
      const payload: FetchAssessmentListingPayload = {
        page: page - 1,
        limit: limit,
        selected_searched_user: this.selected_searched_user.map((i) => i.id),
        date_picker_field: this.date_picker_field
      };
      await this.fetch_assessment_listing(payload);
    },
    async fetch_listing() {
      await this.extract_assessment_listing(
        this.get_assessment_listing.pagination
      );
    },
    async format_date_range(dates: string[]) {
      if (dates.length === 1)
        this.date_range_value = moment(dates[0]).format("DD MMM YYYY");
      else if (dates.length === 2) {
        this.date_range_value = moment(dates[0]).format("DD MMM YYYY");
        this.date_range_value +=
          "  to  " + moment(dates[1]).format("DD MMM YYYY");
        this.date_picker_menu = false;
        this.set_selected_dates(this.date_picker_field);
        await this.extract_assessment_listing(
          this.get_assessment_listing.pagination
        );
      }
    },
    reset_users() {
      this.selected_searched_user = [];
    },
    reset_dates() {
      this.date_picker_field = [];
      this.delete_selected_dates();
    }
  }
});
